<template>
  <div class="inside-page-content">
    <div class="paper" style="background-color: #F7F9FE;">
      <div class="top-wrapper">
        <div class="job-title">
          <span>{{ detailData.positionName }}</span>
          <span class="money">{{ salary }}</span>
        </div>
        <div
          style="margin-top: 20px;"
        >{{ detailData.base }} | {{ experience }} | {{ education }} |{{ detailData.isReceiveGraduate=='1'?'接收应届生':'不接收应届生' }}</div>
        <div class="tag-wrapper">
          <div class="tag-item" v-for="tag in positionAdvantages" :key="tag">{{ tag }}</div>
        </div>
      </div>
      <div class="other-wrapper">
        <div class="avatar-row">
          <img class="avatar" src="@/static/images/rencaizhaopinhome.png" />
          <div class="right-info">
            <div class="red-color">
              <span>{{ detailData.contactName }}</span>
              <span style="margin-left: 40px;">{{ detailData.contactPosition }}</span>
            </div>
            <div>
              <span>联系人</span>
              <span style="margin-left: 10px;" class="h4 red-color">{{ detailData.contactName }}</span>
            </div>
            <div>联系电话：{{ detailData.contactPhone }}</div>
          </div>
        </div>
        <div class="other-title">职位描述</div>
        <div style="display: flex;flex-wrap: wrap;padding-left: 50px;">
          <div style="width: 50%;margin-bottom: 20px;">招聘人数：{{detailData.recruitNum}}人</div>
          <div style="width: 50%;margin-bottom: 20px;">语言要求：{{ detailData.language }}</div>
          <div style="width: 50%;margin-bottom: 20px;">到岗时间：{{ detailData.arrivalTime }}</div>
          <div style="width: 50%;margin-bottom: 20px;">年龄要求：{{ detailData.age}}</div>
        </div>
        <div class="other-title">公司简介</div>
        <div style="padding: 20px;">
          <div v-html="detailData.companyIntroduction" class="ql-editor"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      notice_id: null,
      detailData: {},
    }
  },
  computed: {
    salary() {
      const v = this.detailData?.salary
      if (v == '1') {
        return '面议'
      }
      if (v == '2') {
        return '3000-6000'
      }
      if (v == '3') {
        return '6000-10000'
      }
      if (v == '4') {
        return '10000-20000'
      }
      return '--'
    },
    education() {
      const v = this.detailData?.education
      if (v == '1') {
        return '学历不限'
      }
      if (v == '2') {
        return '专科及以上'
      }
      if (v == '3') {
        return '本科及以上'
      }
      if (v == '4') {
        return '硕士及以上'
      }
      if (v == '5') {
        return '博士'
      }
      return '--'
    },
    experience() {
      const v = this.detailData?.experience
      if (v == '1') {
        return '经验不限'
      }
      if (v == '2') {
        return '3年以内'
      }
      if (v == '3') {
        return '3-5年'
      }
      if (v == '4') {
        return '5年以上'
      }
      return '--'
    },
    positionAdvantages() {
      var parkPortrait = this.detailData?.positionAdvantages ?? ''
      var tags1 = parkPortrait.split(',')
      var tags2 = parkPortrait.split('，')
      return tags1.length > tags2.length ? tags1 : tags2;
    },
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.talentRecruitDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.top-wrapper {
  height: 180px;
  padding: 20px;
  background-color: #fff;

  .job-title {
    font-weight: 700%;
    font-size: 150%;

    .money {
      color: #ff4614;
      margin-left: 40px;
    }
  }

  .tag-wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .tag-item {
      padding: 0 10px;
      background-color: #d2e3fa;
      border-radius: 2px;
      height: 30px;
      line-height: 30px;
      margin-right: 10px;
      font-size: 15px;
    }
  }
}

.other-wrapper {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;

  .avatar-row {
    display: flex;
    height: 100px;

    .avatar {
      height: 100%;
      width: 100px;
      object-fit: contain;
      margin-right: 20px;
    }

    .right-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .red-color {
        color: #ff4614;
      }
    }
  }

  .other-title {
    font-weight: 700;
    margin: 50px 0 20px 10px;
  }
}
</style>
